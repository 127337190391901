import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'
import {useLocation, useNavigate} from '@reach/router'
import styled from 'styled-components'
import {Collapse} from 'react-collapse'
import VimeoPlayer from 'react-player/vimeo'
import YouTubePlayer from 'react-player/youtube'
import {ReactPlayerProps} from 'react-player'
import {MainLayout} from 'layouts'
import {useUser} from 'hooks'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown, faCheckCircle, faChevronUp, faLock} from '@fortawesome/free-solid-svg-icons'
import {LoadingSpinner, SEO} from 'components'
import {LockSVG} from 'images/svgs'
import {useConvertKit, useAmplitude, useTimer} from 'hooks'

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: var(--black);
  color: var(--white);
`

const Container = styled.div`
  --grey-1: #141516;
  --grey-2: #212121;

  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  width: 100%;
  background-color: black;
  color: var(--white);

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`

const Pane = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 25px;
  border-top: 2px solid var(--grey-1);

  @media only screen and (min-width: 1200px) {
    flex: unset;
    flex-shrink: 0;
    border-top: none;
    border-right: 2px solid var(--grey-1);
    width: 33vw;
    max-width: 640px;
  }
`

const LessonDetails = styled.div`
  width: var(--mobile-width);

  @media only screen and (min-width: 1200px) {
    width: 90%;
    flex: 1;
  }
`

const ContentTitle = styled.p`
  font-size: 0.875em;
  font-weight: 600;
  font-family: var(--primary-font);
  color: var(--blue);
`

const LessonTitle = styled.h2`
  margin-top: 0;
`

const Description = styled.p`
  margin-bottom: 50px;
`

const Resources = styled.div`
  margin: 25px 0;

  h4 {
    margin-bottom: 0.5em;
  }

  a {
    display: block;
    color: var(--blue);
  }

  a:hover {
    text-decoration: underline;
  }
`

const PlaylistPane = styled.div`
  width: 100%;
  font-family: var(--primary-font);
  background-color: var(--grey-2);
`

const PlaylistHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: none;
  width: 100%;
  padding: 0.75em calc((100vw - var(--mobile-width)) / 2);

  font-family: var(--primary-font);
  font-size: 1em;
  font-weight: 600;
  text-align: left;
  background-color: var(--grey-1);
  color: var(--white);

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0.75em 25px;
  }
`

const LessonComponent = styled(Link)<{selected: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0.75em calc((100vw - var(--mobile-width)) / 2);

  background-color: ${({selected}) => (selected ? '#ffffff10' : 'transparent')};
  color: var(--white);

  @media only screen and (min-width: 700px) {
    &:hover {
      background-color: #ffffff0a;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 0.75em 25px;
  }
`

const LessonComponentIcons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-shrink: 0;

  margin-left: 0.5rem;
  width: 2.5rem;
`

const ToggleIcon = styled(FontAwesomeIcon)`
  @media only screen and (min-width: 700px) {
    transition: opacity var(--transition);
    ${PlaylistHeader}:hover & {
      opacity: 0.5;
    }
  }
`

const PlayingIndicator = styled.div`
  padding-bottom: 0.5em;
  color: var(--dark-grey);
  font-weight: 600;
`

const LessonComponentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  svg {
    color: var(--dark-grey);
  }
`

const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vw;

  @media only screen and (min-width: 1200px) {
    height: unset;
    width: 100%;
  }
`

const Lock = styled(LockSVG)`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;

  @media only screen and (min-width: 1200px) {
    width: 200px;
    height: 200px;
    margin-bottom: 25px;
  }
`

const LockedPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding: 50px;

  h1 {
    font-size: 1.5em;
  }

  p {
    margin: 0;
  }

  @media only screen and (min-width: 700px) {
    h1 {
      font-size: 2em;
    }
  }

  @media only screen and (min-width: 700px) {
    h1 {
      font-size: 2.625em;
    }
  }
`

const LockedPlayer = (props: {stopTimer: () => void}) => {
  useEffect(() => {
    props.stopTimer()
  }, [])

  return (
    <LockedPlayerContainer>
      <Lock />
      <h1>Creator Only Content</h1>
      <p>To access this video you{`'`}ll need to upgrade your account.</p>
    </LockedPlayerContainer>
  )
}

interface VideoPlayerProps extends ReactPlayerProps {
  type: 'vimeo' | 'youtube'
  source: string
}

const VideoPlayer = ({type, source, ...props}: VideoPlayerProps) => {
  switch (type) {
    case 'vimeo':
      return (
        <VimeoPlayer width='100%' height='100%' controls url={`https://player.vimeo.com/video/${source}`} {...props} />
      )
    case 'youtube':
      return (
        <YouTubePlayer width='100%' height='100%' controls url={`https://youtube.com/embed/${source}`} {...props} />
      )
  }
}

interface Content {
  type: 'project' | 'topic'
  slug: string
  free: boolean
  title: string
  description: string
  lessons: Array<{
    free: boolean
    type: 'vimeo' | 'youtube'
    source: string
    title: string
    slug: string
    description: string
    watched: boolean
    resources: Array<{
      label: string
      url: string
    }>
  }>
}

export const LearnPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {addTags} = useConvertKit()
  const {logEvent} = useAmplitude()
  const timer = useTimer()
  const [isLoading, setLoading] = useState(true)
  const {user, projects: userProjects, topics: userTopics, setProgress} = useUser()
  const [contentObject, setContent] = useState<Content>()
  const [selectedLesson, selectLesson] = useState(0)
  const [isPlaylistOpen, setPlaylistOpen] = useState(true)

  const abort = () => navigate('/app/dashboard', {replace: true})

  const sendWatchTime = () => {
    // Check if the content has been loaded
    if (!contentObject) return

    /* Logging a "heartbeat" of user activity. Timer resets every time a heartbeat is sent.
     *  Under 5 seconds is too soon for another heartbeat to be sent
     *  Over 20 minutes and we assume the user had some period of inactivity since the last heartbeat
     */
    if (timer.isActive && timer.seconds > 5 && timer.seconds < 1200) {
      logEvent('activity-tick', {
        contentType: contentObject.type,
        slug: contentObject.slug,
        lessonSlug: contentObject.lessons[selectedLesson].slug,
        duration: timer.seconds,
      })
    }
  }

  const logWatchTime = () => {
    // Check if the content has been loaded
    if (!contentObject) return

    // Check if the timer has not already started
    if (!timer.isActive) {
      // Start the timer
      return timer.start()
    }

    sendWatchTime()
    timer.restart()
  }

  useEffect(() => {
    // Pull query information, if any, from url
    const queries = location.search.slice(1).split('&')
    let contentType: 'project' | 'topic'
    let slug: string
    let task: number
    let temp: string | undefined

    if (queries.length < 2) abort()

    if ((temp = queries.find((q) => q.includes('contentType')))) {
      if (temp.split('=')[1] !== 'topic' && temp.split('=')[1] !== 'project') abort()
      contentType = temp.split('=')[1] as 'topic' | 'project'
    } else return

    if ((temp = queries.find((q) => q.includes('slug')))) {
      slug = temp.split('=')[1]
    } else return

    if (queries.length > 2) {
      if ((temp = queries.find((q) => q.includes('task')))) {
        task = parseInt(temp.split('=')[1])
      }
    }

    if (!slug) abort()

    let currentUserContent
    switch (contentType) {
      case 'project':
        currentUserContent = userProjects?.find((p) => p.slug === slug)
        break
      case 'topic':
        currentUserContent = userTopics?.find((t) => t.slug === slug)
        break
    }

    const getContent = async () => {
      // Pull information about the content
      const response = await fetch(`https://cms.thecodex.me/${contentType}s?slug=${slug}`)

      const jsonResponse = await response.json()

      if (jsonResponse.length === 0) return abort()

      const rawContent = jsonResponse[0]

      let formattedContent: Content

      switch (contentType) {
        case 'project':
          formattedContent = {
            type: contentType,
            slug: slug,
            free: rawContent.free,
            title: rawContent.title,
            description: rawContent.description,
            lessons: [
              {
                free: true,
                source: rawContent.introduction.source,
                title: 'Welcome to the Project!',
                slug: 'welcome',
                description: rawContent.overview,
                watched: currentUserContent
                  ? currentUserContent.watchedLectures.includes(rawContent.introduction.source)
                  : false,
                resources: [],
                type: rawContent.introduction.type,
              },
              ...rawContent.lessons.map((lesson) => ({
                free: lesson.free,
                source: lesson.video.source,
                title: lesson.title,
                slug: lesson.slug,
                description:
                  !lesson.description || lesson.description.length === 0
                    ? '(No description provided.)'
                    : lesson.description,
                watched: currentUserContent ? currentUserContent.watchedLectures.includes(lesson.video.source) : false,
                resources: lesson.resources.map((resource) => {
                  return {label: resource.label, url: resource.url}
                }),
                type: lesson.video.type,
              })),
            ],
          }
          break
        case 'topic':
          formattedContent = {
            type: contentType,
            slug: slug,
            free: rawContent.free,
            title: rawContent.title,
            description: rawContent.description,
            lessons: [
              ...rawContent.lessons.map((lesson) => ({
                free: lesson.free,
                source: lesson.video.source,
                title: lesson.title,
                slug: lesson.slug,
                description:
                  !lesson.description || lesson.description.length === 0
                    ? '(No description provided.)'
                    : lesson.description,
                watched: currentUserContent ? currentUserContent.watchedLectures.includes(lesson.video.source) : false,
                resources: lesson.resources.map((resource) => {
                  return {label: resource.label, url: resource.url}
                }),
                type: lesson.video.type,
              })),
            ],
          }
          break
      }

      setContent(formattedContent)

      // If no task was specified, pull the last watched lecture from the user object
      if (task === undefined) {
        const potentialSelection = formattedContent.lessons.findIndex((lesson) => !lesson.watched)

        if (potentialSelection === -1) {
          // Changes the URL without reloading
          window.history.replaceState(null, '', `${location.pathname}?contentType=${contentType}&slug=${slug}&task=0`)
          selectLesson(0)
          setLoading(false)
          return
        }

        window.history.replaceState(
          null,
          '',
          `${location.pathname}?contentType=${contentType}&slug=${slug}&task=${potentialSelection}`
        )
        selectLesson(potentialSelection)
      } else {
        if (isNaN(task) || task < 0 || task >= formattedContent.lessons.length) {
          // Changes the URL without reloading
          window.history.replaceState(null, '', `${location.pathname}?contentType=${contentType}&slug=${slug}&task=0`)
          selectLesson(0)
          setLoading(false)
          return
        }
        selectLesson(task)
      }

      setLoading(false)
    }

    getContent()
  }, [location, userProjects, userTopics])

  // Change the url when the lesson changes
  useEffect(() => {
    if (contentObject && contentObject.type && contentObject.slug) {
      window.history.replaceState(
        null,
        '',
        `${location.pathname}?contentType=${contentObject.type}&slug=${contentObject.slug}&task=${selectedLesson}`
      )
    }
  }, [selectedLesson])

  // Saves watch time on exit
  // NOTE: This likely won't work if the user closes the window
  //         (as opposed to just clicking to a new page)
  useEffect(() => {
    return sendWatchTime
  }, [])

  const pluralize = (type: 'project' | 'topic') => {
    switch (type) {
      case 'project':
        return 'projects'
      case 'topic':
        return 'topics'
    }
  }

  if (isLoading) {
    return (
      <MainLayout theme='dark'>
        <LoadingContainer>
          <LoadingSpinner color='var(--white)' />
        </LoadingContainer>
      </MainLayout>
    )
  }

  return (
    <MainLayout theme='dark'>
      <SEO title={contentObject?.lessons[selectedLesson].title} />
      <Container>
        <Pane>
          <LessonDetails>
            <ContentTitle>{contentObject?.title}</ContentTitle>
            <LessonTitle>{contentObject?.lessons[selectedLesson].title}</LessonTitle>
            <Description>{contentObject?.lessons[selectedLesson].description}</Description>
            {contentObject?.lessons[selectedLesson].resources.length !== 0 && (
              <Resources>
                <h4>Resources</h4>
                {contentObject?.lessons[selectedLesson].resources.map((resource, index) => (
                  <a key={index} href={resource.url} target='_blank' rel='noreferrer noopener external'>
                    {resource.label}
                  </a>
                ))}
              </Resources>
            )}
          </LessonDetails>
          <PlaylistPane>
            <PlaylistHeader onClick={() => setPlaylistOpen(!isPlaylistOpen)}>
              {contentObject?.title}
              <ToggleIcon icon={isPlaylistOpen ? faChevronDown : faChevronUp} size='1x' />
            </PlaylistHeader>
            <Collapse isOpened={isPlaylistOpen}>
              {contentObject?.lessons.map((lesson, index) => (
                <LessonComponent
                  key={index}
                  to={`/app/learn?contentType=${contentObject.type}&slug=${contentObject.slug}&task=${index}`}
                  selected={selectedLesson === index}
                >
                  {selectedLesson === index && <PlayingIndicator>Currently Playing</PlayingIndicator>}
                  <LessonComponentContent>
                    <span>
                      {contentObject.type === 'project' ? (index === 0 ? '' : `${index}. `) : `${index + 1}. `}
                      {lesson.title}
                    </span>
                    <LessonComponentIcons>
                      {user?.proStatus === 'inactive' && !lesson?.free && <FontAwesomeIcon icon={faLock} />}
                      {lesson.watched && <FontAwesomeIcon icon={faCheckCircle} />}
                    </LessonComponentIcons>
                  </LessonComponentContent>
                </LessonComponent>
              ))}
            </Collapse>
          </PlaylistPane>
        </Pane>
        <PlayerContainer>
          {user?.proStatus === 'inactive' && !contentObject?.lessons[selectedLesson]?.free ? (
            <LockedPlayer stopTimer={timer.stop} />
          ) : (
            contentObject?.lessons[selectedLesson].source && (
              <VideoPlayer
                type={contentObject?.lessons[selectedLesson].type}
                source={contentObject?.lessons[selectedLesson].source}
                onStart={logWatchTime}
                onPlay={logWatchTime}
                onPause={logWatchTime}
                onProgress={({played, playedSeconds}) => {
                  if (playedSeconds > 5) {
                    switch (contentObject.type) {
                      case 'topic':
                        if (user === null || userTopics === null) break

                        // Create firebase entry
                        if (!userTopics.find((topic) => contentObject.slug === topic.slug)) {
                          setProgress(pluralize(contentObject.type), contentObject.slug)
                        }
                        break
                      case 'project':
                        if (user === null || userProjects === null) break

                        // Create firebase entry
                        if (!userProjects.find((project) => contentObject.slug === project.slug)) {
                          setProgress(pluralize(contentObject.type), contentObject.slug)
                        }

                        // Add tag to user's email on ConvertKit
                        addTags(user.email, ['Project Start'])
                        break
                    }
                  }

                  // Check if the player is in the last ten seconds of video
                  if (
                    (1 / played) * playedSeconds - playedSeconds < 10 &&
                    !contentObject.lessons[selectedLesson].watched
                  ) {
                    setProgress(
                      pluralize(contentObject.type),
                      contentObject.slug,
                      contentObject?.lessons[selectedLesson].source
                    )

                    // Check if the user just completed the whole project
                    if (
                      contentObject.type === 'project' &&
                      contentObject.lessons.filter((lesson) => lesson.watched).length + 1 ===
                        contentObject.lessons.length
                    ) {
                      if (user !== null) {
                        // Add a tag to ConvertKit
                        addTags(user.email, ['Project Complete'])
                      }
                    }
                  }
                }}
                onEnded={() => {
                  logWatchTime()
                  if (selectedLesson < contentObject.lessons.length - 1) {
                    selectLesson((prev) => prev + 1)
                  }
                }}
              />
            )
          )}
        </PlayerContainer>
      </Container>
    </MainLayout>
  )
}

export default LearnPage
