import React, {useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate, WindowLocation} from '@reach/router'

import {ActionButton, LinkButton, LoadingSpinner, Input, Password, SEO} from 'components'
import {ModalLayout} from 'layouts'
import {useAuth} from 'hooks'

const Modal = styled.div`
  border-radius: 10px;
  width: var(--mobile-width);
  max-width: 500px;
  padding: 5vw;

  text-align: center;

  background-color: var(--white);
  filter: var(--shadow);

  h1 {
    margin-top: 0;
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 75px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`

const LoadingSection = styled.div`
  min-height: 95px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const ErrorMessage = styled.p`
  min-height: 1.2em;
  margin: 0 0 25px;
  font-size: 0.9em;
  color: var(--red);
`

export const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {isLoading, user, signIn} = useAuth()

  // const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    switch (name) {
      case 'email':
        setEmail(value)
        return
      case 'password':
        setPassword(value)
        return
    }
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')

    const result = await signIn(email, password)

    switch (result) {
      case 'success':
        return
      case 'invalid-credentials':
        setError('Your email or password is invalid.')
        break
      case 'error':
        setError('Something went wrong. Please try again later.')
        break
    }
  }

  if (user) {
    const {state} = location as WindowLocation<{successUrl: string}>

    if (state !== null && state.successUrl) {
      console.log('Heading to ', state.successUrl)
      navigate(state.successUrl, {replace: true})
    } else {
      console.log('Heading to /app/dashboard')
      navigate('/app/dashboard', {replace: true})
    }
  }

  return (
    <ModalLayout>
      <SEO title='Login' />
      <Modal>
        <h1>Welcome back.</h1>
        <Form onSubmit={onSubmit}>
          <Input required label='Email' type='email' name='email' disabled={isLoading} onChange={onChange} />
          <Password required label='Password' name='password' disabled={isLoading} onChange={onChange} />
          <LoadingSection>
            {isLoading ? (
              <LoadingSpinner color='var(--dark-blue)' />
            ) : (
              <>
                <ErrorMessage>{error}</ErrorMessage>
                <ActionButton tier='primary' type='submit'>
                  Login
                </ActionButton>
              </>
            )}
          </LoadingSection>
        </Form>
        <p>
          Don{`'`}t have an account?{' '}
          <LinkButton tier='tertiary' to='/app/get-started' replace={true} state={location.state}>
            Sign up
          </LinkButton>
          .
        </p>
        <p>
          Can{`'`}t log in?{' '}
          <LinkButton tier='tertiary' to='/app/forgot-password' replace={true} state={location.state}>
            Reset my password
          </LinkButton>
          .
        </p>
      </Modal>
    </ModalLayout>
  )
}

export default LoginPage
