import React from 'react'
import {useNavigate, Router} from '@reach/router'
import styled from 'styled-components'
import {Billing, Checkout, Dashboard, Learn, Login, ForgotPassword, SignUp, Settings} from 'app'
import {useAuth, useUser} from 'hooks'
import {GlobalStyle} from 'layouts'
import NotFoundPage from './404'
import {LoadingSpinner} from 'components'

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: var(--white);
`

const PrivateRoute = ({component: Component, ...rest}) => {
  const navigate = useNavigate()
  const {isLoading} = useUser()
  const {user} = useAuth()

  if (isLoading) {
    return (
      <LoadingScreen>
        <GlobalStyle />
        <LoadingSpinner color='var(--dark-blue)' />
      </LoadingScreen>
    )
  }

  if (user === null) {
    // navigate('/app/login')
    // return null
  }

  return <Component {...rest} />
}

const AppRoute = () => (
  <Router basepath='/app'>
    <PrivateRoute default component={NotFoundPage} />
    <PrivateRoute path='/dashboard' component={Dashboard} />
    <PrivateRoute path='/learn' component={Learn} />
    <PrivateRoute path='/settings' component={Settings} />
    <PrivateRoute path='/checkout' component={Checkout} />
    <PrivateRoute path='/billing' component={Billing} />
    <Login path='/login' />
    <SignUp path='/get-started' />
    <ForgotPassword path='/forgot-password' />
  </Router>
)

export default AppRoute
