import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {navigate} from '@reach/router'
import {GlobalStyle} from 'layouts'
import {useFunctions, useUser} from 'hooks'
import {SEO, LinkButton, WordLogo, LoadingSpinner} from 'components'

const CustomLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  padding: 5vw;

  text-align: center;
  background-color: var(--white);
  color: var(--dark-blue);
`

const Logo = styled(WordLogo)`
  height: 200px;
  width: 100%;
`

const LoadingSection = styled.div`
  min-height: 95px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const BillingPage = () => {
  const {user} = useUser()
  const {createStripeBillingPortal} = useFunctions()
  const [status, setStatus] = useState<'loading' | 'error' | 'redirecting'>('loading')

  const leave = () => navigate('/app/dashboard', {replace: true})

  useEffect(() => {
    const getCheckoutSession = async () => {
      if (user === null || !user.customerId || user.customerId === null) return leave()
      const result = await createStripeBillingPortal(user.customerId)

      if (result === 'error' || result === 'failure') return setStatus('error')

      // Redirect to checkout page
      setStatus('redirecting')
      window.location.href = result.url
    }

    getCheckoutSession()
  }, [])

  switch (status) {
    case 'loading':
      return (
        <CustomLayout>
          <SEO title='Manage Billing' />
          <GlobalStyle />
          <Logo color='var(--black)' />

          <h4>
            Just fetching your things, one moment... <span aria-label='*running*'>🏃🏿‍♀️</span>
          </h4>
          <LoadingSection>
            <LoadingSpinner />
          </LoadingSection>
        </CustomLayout>
      )
    case 'error':
      return (
        <CustomLayout>
          <SEO title='Manage Billing' />
          <GlobalStyle />
          <Logo color='var(--black)' />

          <h5>Something went wrong.</h5>
          <LinkButton tier='primary' to='/app/settings'>
            Back
          </LinkButton>
        </CustomLayout>
      )
    case 'redirecting':
      return (
        <CustomLayout>
          <SEO title='Manage Billing' />
          <GlobalStyle />
          <Logo color='var(--black)' />

          <h4>
            Just fetching your things, one moment... <span aria-label='*running*'>🏃🏿‍♀️</span>
          </h4>
          <LoadingSection>
            <LoadingSpinner />
            <p>
              <i>Redirecting...</i>
            </p>
          </LoadingSection>
        </CustomLayout>
      )
  }
}

export default BillingPage
