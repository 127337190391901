import React from 'react'
import {useUser} from 'hooks'
import GettingStartedPage from './gettingStarted'
import OnboardingPage from './onboarding'
import DashboardHomePage from './home'

const DashboardPage = () => {
  const {user, projects: userProjects, topics: userTopics} = useUser()

  if (!user?.events.includes('onboarded')) {
    return <OnboardingPage />
  }

  if (userProjects?.length === 0 && userTopics?.length === 0) {
    return <GettingStartedPage />
  }

  return <DashboardHomePage />
}

export default DashboardPage
