import React from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {useUser} from 'hooks'
import {MainLayout, ModalLayout} from 'layouts'
import {LinkButton, ProjectCard, SEO} from 'components'
import useStrapiContent from './useStrapiContent'

const GetStartedContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  margin: 50px;
  padding: 50px;
  width: var(--mobile-width);

  text-align: center;
  background-color: var(--white);
  filter: var(--shadow);

  h3 {
    margin: 0;
  }

  ol {
    padding: 0;
  }

  ol li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 25px 0;

    h4 {
      margin: 0 1em;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);

    ol li {
      flex-direction: row;
      align-items: center;

      margin: 10px 0;
    }
  }
`

const NumberedBullet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  width: 2rem;
  height: 2rem;

  background-color: var(--blue);
  color: var(--white);

  font-family: var(--title-font);
`

const ProjectSuggestion = styled.div`
  display: none;

  @media only screen and (min-width: 700px) {
    display: flex;

    margin-bottom: 25px;
  }
`

const BorderedProjectCard = styled(ProjectCard)`
  border: 2px solid var(--light-grey);

  @media only screen and (min-width: 700px) {
    margin-right: 20px;
    &:hover {
      border-color: var(--white);
    }
  }
`

const GettingStartedPage = () => {
  const {user} = useUser()
  const {strapiProjects} = useStrapiContent()

  return (
    <ModalLayout>
      <SEO title='Dashboard' />
      <GetStartedContainer>
        <h3>
          Two ways to level up <span aria-label='Look below'>💪</span>
        </h3>
        <ol>
          <li>
            <NumberedBullet>1</NumberedBullet>
            <h4>Start Your First Project</h4>
            <LinkButton tier='tertiary' to='/projects'>
              View all projects <FontAwesomeIcon icon={faArrowRight} />{' '}
            </LinkButton>
          </li>
          <ProjectSuggestion>
            {Object.values(strapiProjects)
              .filter((strapiProject) => (user?.proStatus === 'active' ? true : strapiProject.free))
              .slice(0, 2)
              .map((strapiProject) => (
                <BorderedProjectCard key={strapiProject.slug} project={strapiProject} />
              ))}
          </ProjectSuggestion>
          <li>
            <NumberedBullet>2</NumberedBullet>
            <h4>Start Learning a Topic</h4>
            <LinkButton tier='tertiary' to='/topics'>
              View all topics <FontAwesomeIcon icon={faArrowRight} />{' '}
            </LinkButton>
          </li>
        </ol>
      </GetStartedContainer>
    </ModalLayout>
  )
}

export default GettingStartedPage
