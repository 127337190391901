import React, {useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img, {FluidObject} from 'gatsby-image'
import styled from 'styled-components'
import {ModalLayout} from 'layouts'
import {ActionButton, AnchorButton, LoadingSpinner, SEO} from 'components'
import {PersonCareer, PersonStudent, PersonPassion} from 'images/svgs'
import {useUser, useConvertKit, useAmplitude} from 'hooks'

interface SlideProps {
  next: () => void
  finish: () => void
}

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  margin: 50px;
  padding: 25px;
  width: var(--mobile-width);

  text-align: center;
  background-color: var(--white);
  filter: var(--shadow);

  h3 {
    margin: 0;
  }

  ol {
    padding: 0;
  }

  ol li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 25px 0;

    h4 {
      margin: 0 1em;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
    padding: 50px;

    ol li {
      flex-direction: row;
      align-items: center;

      margin: 10px 0;
    }
  }
`

const NewcomerSlideContainer = styled(SlideContainer)`
  @media only screen and (min-width: 700px) {
    max-width: 1000px;
  }
`

const NewcomerContent = styled.div`
  display: flex;
`

const Blurb = styled.div`
  flex: 2;
  text-align: left;
`

const Graphic = styled(Img)<{fluid: FluidObject}>`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: unset;
    flex: 1;
    margin-left: 20px;
  }
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`

const NewcomerButtonDiv = styled(ButtonDiv)`
  @media only screen and (min-width: 700px) {
    align-items: flex-start;
  }
`

interface NewcomerSlideProps extends SlideProps {
  stepsGraphic: FluidObject
}

const NewcomerSlide = (props: NewcomerSlideProps) => {
  const {user} = useUser()

  const existingUser = user?.events.includes('transferred')

  return (
    <NewcomerSlideContainer>
      {existingUser ? <h1>Brand New Platform!</h1> : <h1>It{`'`}s time to build!</h1>}
      <NewcomerContent>
        <Blurb>
          {existingUser ? (
            <>
              {' '}
              <p>Hey there!</p>
              <p>
                We’re super excited to announce a brand new platform on The Codex. We’ve redesigned the learning
                experience from the ground up and made projects front and center.
              </p>
              <p>
                After talking to numerous students, we’ve decided to double down on Projects, breaking up courses into
                individual topics you can take at your own pace.
              </p>
              <p>
                Rather than being bogged down by a lengthy course, the exact same content is available as topics for you
                to watch on the platform. You haven’t lost access to the courses, they’re all still there in the Topics
                tab!
              </p>
              {user?.proStatus === 'active' && (
                <p>
                  If at any point in time you would like to go back to the old course-based experience,{' '}
                  <strong>
                    you can head to{' '}
                    <AnchorButton tier='tertiary' href='https://old.thecodex.me'>
                      old.thecodex.me
                    </AnchorButton>{' '}
                    to access the old site
                  </strong>
                  . We’ll be maintaining this platform for the new few months until we slowly wind it down.
                </p>
              )}
              <p>
                We’ve been hard at work with this new platform and we can’t wait to see you start learning on it.
                Looking forward to hear what you think!
              </p>
              <p>
                Best,
                <br />
                Avinash
              </p>
            </>
          ) : (
            <>
              <p>
                <strong>At The Codex, we do things a little differently...</strong>
              </p>
              <p>
                Programming should be fun. It should be exciting. However, most of us get stuck in lengthy, boring
                coding classes. Or we’re trapped in tutorial hell.
              </p>
              <p>
                You’ve been trying to figure out the answer to{' '}
                <strong>
                  {`"`}how do I build?{`"`}
                </strong>
              </p>
              <p>
                I’ve been there. And after years of building and launching projects, I’m excited to tell you it’s not
                rocket science.
              </p>
              <p>
                The Codex makes build projects easy and exciting. We show you how to build fun and practical projects
                from the ground up, like building a song lyric generator or predicting stock prices.
              </p>
              <p>
                Add your new projects to your resume and stand out in the application process for internships and jobs.
                The sky is the limit for what you can achieve by building projects.
              </p>
              <p>
                Before you start building, we have <strong>three quick questions</strong> to tailor your experience.
              </p>
            </>
          )}
          <NewcomerButtonDiv>
            <ActionButton tier='primary' onClick={props.next}>
              Let{`'`}s get started!
            </ActionButton>
            <div style={{height: 20}} />
            <ActionButton tier='tertiary' onClick={props.finish}>
              Take me to the dashboard instead {`->`}
            </ActionButton>
          </NewcomerButtonDiv>
        </Blurb>
        <Graphic fluid={props.stepsGraphic} imgStyle={{objectFit: 'contain'}} />
      </NewcomerContent>
    </NewcomerSlideContainer>
  )
}

const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  align-self: center;
`

interface ProgressBarNumberProps {
  selected: number
  slot: number
}

const backgroundColor = ({selected, slot}: ProgressBarNumberProps) => {
  if (slot === selected) return `var(--blue)`
  else if (slot < selected) return `var(--medium-grey)`
  else return `var(--white)`
}

const ProgressBarNumber = styled.div<ProgressBarNumberProps>`
  flex: 1;
  padding: 4px 0;
  border: 2px solid ${({selected, slot}) => (selected === slot ? `var(--blue)` : `var(--medium-grey)`)};
  border-left-width: 1px;
  border-right-width: 1px;
  background-color: ${backgroundColor};
  color: ${({selected, slot}) => (selected === slot ? `var(--white)` : `var(--dark-grey)`)};
`

const ProgressBarNumberLeft = styled(ProgressBarNumber)`
  border-left-width: 2px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
`

const ProgressBarNumberRight = styled(ProgressBarNumber)`
  border-right-width: 2px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`

const ProgressBar = (props: {selected: number}) => (
  <ProgressBarContainer>
    <ProgressBarNumberLeft selected={props.selected} slot={1}>
      1
    </ProgressBarNumberLeft>
    <ProgressBarNumber selected={props.selected} slot={2}>
      2
    </ProgressBarNumber>
    <ProgressBarNumberRight selected={props.selected} slot={3}>
      3
    </ProgressBarNumberRight>
  </ProgressBarContainer>
)

const RoleSlideContainer = styled(SlideContainer)`
  align-items: center;

  @media only screen and (min-width: 700px) {
    max-width: 1000px;
  }
`

const Roles = styled.div`
  width: 100%;

  @media only screen and (min-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const RoleCard = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 2px solid ${({selected}) => (selected ? `var(--blue)` : `var(--light-grey)`)};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;

  background-color: var(--white);
  transition: var(--transition);

  svg {
    width: 100%;
  }

  strong {
    margin-top: 10px;
  }

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
      filter: var(--shadow);
    }
  }

  @media only screen and (min-width: 900px) {
    padding: 20px;
    width: 280px;
    height: 280px;

    strong {
      margin-top: 20px;
    }
  }
`

type Role = 'Professional' | 'Student' | 'Hobbyist' | null
interface RoleSlideProps extends SlideProps {
  roleState: [role: Role, setRole: (role: Role) => void]
}

const RoleSlide = (props: RoleSlideProps) => {
  const [role, setRole] = props.roleState

  return (
    <RoleSlideContainer>
      <ProgressBar selected={1} />
      <h1>Which of these resonates with you most?</h1>
      <Roles>
        <RoleCard selected={role === 'Professional'} onClick={() => setRole('Professional')}>
          <PersonCareer />
          <strong>A professional, looking for a new job</strong>
        </RoleCard>
        <RoleCard selected={role === 'Student'} onClick={() => setRole('Student')}>
          <PersonStudent />
          <strong>A student, looking to get better</strong>
        </RoleCard>
        <RoleCard selected={role === 'Hobbyist'} onClick={() => setRole('Hobbyist')}>
          <PersonPassion />
          <strong>A hobbyist, looking to build your ideas</strong>
        </RoleCard>
      </Roles>
      <ButtonDiv>
        <ActionButton tier='primary' onClick={props.next} disabled={role === null}>
          Next
        </ActionButton>
        <div style={{height: 20}} />
        <ActionButton tier='tertiary' onClick={props.finish}>
          Take me to the dashboard instead {`->`}
        </ActionButton>
      </ButtonDiv>
    </RoleSlideContainer>
  )
}

const CategoryButton = styled.button<{selected: boolean}>`
  border: none;
  padding: 0;
  background: none;
  font-family: var(--secondary-font);
  font-size: 0.9em;

  border: 2px solid ${({selected}) => (selected ? `var(--blue)` : `var(--medium-grey)`)};
  border-radius: 50px;
  padding: 10px 20px;
  margin: 5px;

  background-color: ${({selected}) => (selected ? `var(--blue)` : `var(--white)`)};
  color: ${({selected}) => (selected ? `var(--white)` : `var(--black)`)};
  transition: var(--transition);

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
      border-color: var(--blue);
    }
  }
`

interface CategoriesSlideProps extends SlideProps {
  categories: Array<string>
  selectedCategoriesState: [
    selectedCategories: Array<string>,
    setSelectedCategories: (categories: Array<string>) => void
  ]
}

const CategoriesSlide = (props: CategoriesSlideProps) => {
  const [selectedCategories, setSelectedCategories] = props.selectedCategoriesState

  return (
    <SlideContainer>
      <ProgressBar selected={2} />
      <h1>I am most interested in learning about...</h1>
      <p>(Select at least one)</p>
      <div>
        {props.categories.map((category, i) => (
          <CategoryButton
            key={i}
            type='button'
            selected={selectedCategories.includes(category)}
            onClick={() => {
              if (selectedCategories.includes(category)) {
                setSelectedCategories(selectedCategories.filter((a) => a !== category))
              } else {
                setSelectedCategories([...selectedCategories, category])
              }
            }}
          >
            {category}
          </CategoryButton>
        ))}
      </div>
      <ButtonDiv>
        <ActionButton tier='primary' onClick={props.next} disabled={selectedCategories.length === 0}>
          Next
        </ActionButton>
        <div style={{height: 20}} />
        <ActionButton tier='tertiary' onClick={props.finish}>
          Take me to the dashboard instead {`->`}
        </ActionButton>
      </ButtonDiv>
    </SlideContainer>
  )
}

const ProficiencyOption = styled.button<{selected: boolean}>`
  border: none;
  padding: 0;
  background: none;

  border: 2px solid ${({selected}) => (selected ? `var(--blue)` : `var(--light-grey)`)};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;

  font-family: var(--secondary-font);
  font-size: 1em;
  font-weight: 600;

  background-color: var(--white);
  color: var(--dark-grey);
  transition: var(--transition);

  @media only screen and (min-width: 700px) {
    &:hover {
      cursor: pointer;
      filter: var(--shadow);
    }
  }
`

type Proficiency = 'Python Beginner' | 'Python Intermediate' | 'Python Advanced' | null
interface ProficiencySlideProps extends SlideProps {
  proficiencyState: [proficiency: Proficiency, setProficiency: (proficiency: Proficiency) => void]
}

const ProficiencySlide = (props: ProficiencySlideProps) => {
  const [proficiency, setProficiency] = props.proficiencyState

  return (
    <SlideContainer>
      <ProgressBar selected={3} />
      <h1>How comfortable are you programming in Python?</h1>
      <ProficiencyOption onClick={() => setProficiency('Python Beginner')} selected={proficiency === 'Python Beginner'}>
        I’m not yet comfortable with Python.
      </ProficiencyOption>
      <ProficiencyOption
        onClick={() => setProficiency('Python Intermediate')}
        selected={proficiency === 'Python Intermediate'}
      >
        I’m somewhat comfortable with Python.
      </ProficiencyOption>
      <ProficiencyOption onClick={() => setProficiency('Python Advanced')} selected={proficiency === 'Python Advanced'}>
        I’m quite comfortable with Python.
      </ProficiencyOption>
      <ButtonDiv>
        <ActionButton tier='primary' onClick={props.finish} disabled={proficiency === null}>
          Finish
        </ActionButton>
      </ButtonDiv>
    </SlideContainer>
  )
}

interface OnboardingForm {
  role: Role
  selectedCategories: Array<string>
  proficiency: Proficiency
}

const OnboardingPage = () => {
  const {user, storeEvent} = useUser()
  const {addTags} = useConvertKit()
  const {identify} = useAmplitude()
  const [isLoading, setLoading] = useState(false)
  const [currentSlide, setSlide] = useState(0)
  const [formData, setFormData] = useState<OnboardingForm>({role: null, selectedCategories: [], proficiency: null})

  const {stepsGraphic, allStrapiCategory} = useStaticQuery(graphql`
    query Onboarding {
      stepsGraphic: file(relativePath: {eq: "stepsGraphic.webp"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allStrapiCategory {
        nodes {
          tag
        }
      }
    }
  `)

  // This is just for typing, `user` should never actually be null when on this page
  if (user === null) return <div>Loading...</div>

  const finish = async () => {
    setLoading(true)

    if (formData.role === null || formData.proficiency === null) {
      if (await addTags(user.email, ['Skipped Onboarding'])) {
        identify([['onboarding-complete', false]])
      }
    } else {
      if (await addTags(user.email, [formData.role, formData.proficiency])) {
        identify([
          ['onboarding-complete', true],
          ['onboarding-role', formData.role],
          ['onboarding-proficiency', formData.proficiency],
        ])
      }
    }

    await storeEvent('onboarded')
  }

  const title = user.events.includes('transferred') ? 'Our New Site' : 'Getting Started'

  const slides = [
    <NewcomerSlide
      key={0}
      finish={finish}
      next={() => setSlide(1)}
      stepsGraphic={stepsGraphic.childImageSharp.fluid}
    />,
    <RoleSlide
      key={1}
      finish={finish}
      next={() => setSlide(2)}
      roleState={[formData.role, (role) => setFormData((prev) => ({...prev, role}))]}
    />,
    <CategoriesSlide
      key={2}
      finish={finish}
      next={() => setSlide(3)}
      categories={allStrapiCategory.nodes.map(({tag}) => tag as string)}
      selectedCategoriesState={[
        formData.selectedCategories,
        (selectedCategories) => setFormData((prev) => ({...prev, selectedCategories})),
      ]}
    />,
    <ProficiencySlide
      key={3}
      finish={finish}
      next={() => setSlide(4)}
      proficiencyState={[formData.proficiency, (proficiency) => setFormData((prev) => ({...prev, proficiency}))]}
    />,
  ]

  return (
    <ModalLayout noHeader>
      <SEO title={title} />
      {isLoading ? <LoadingSpinner /> : slides[currentSlide]}
    </ModalLayout>
  )
}

export default OnboardingPage
