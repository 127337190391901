import React, {useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from '@reach/router'

import {ActionButton, Input, LinkButton, LoadingSpinner, SEO, CheckCircle} from 'components'
import {ModalLayout} from 'layouts'
import {useAuth} from 'hooks'

const Modal = styled.div`
  border-radius: 10px;
  width: var(--mobile-width);
  max-width: 500px;
  padding: 5vw;

  text-align: center;

  background-color: var(--white);
  filter: var(--shadow);

  h1 {
    margin-top: 0;
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;

    a:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 75px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`

const LoadingSection = styled.div`
  min-height: 95px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const ErrorMessage = styled.p`
  min-height: 1.2em;
  margin: 0 0 25px;
  font-size: 0.9em;
  color: var(--red);
`

const StyledCheckCircle = styled(CheckCircle)`
  width: 80px;
  height: 80px;
`

const SuccesModal = ({email}: {email: string}) => (
  <Modal>
    <StyledCheckCircle checked color='var(--green)' />
    <h2>Check your inbox!</h2>
    <p>
      We{`'`}ve sent the instructions to reset your password to <i>{email}</i>.
    </p>
    <div style={{height: 15}} />
    <LinkButton tier='primary' to='/app/login'>
      Ok!
    </LinkButton>
  </Modal>
)

export const ForgotPasswordPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {isLoading: isAuthLoading, user, sendPasswordResetEmail} = useAuth()

  const [isLoading, setLoading] = useState(false)
  const [isSuccessful, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    const result = await sendPasswordResetEmail(email)

    switch (result) {
      case 'success':
        setSuccess(true)
        break
      case 'invalid-email':
        setError('This email is invalid.')
        break
      case 'no-user':
        setError('There is no account attached to this email.')
        break
      case 'error':
        setError('Something went wrong. Please try again later.')
        break
    }

    setLoading(false)
  }

  if (user) {
    navigate('/app/dashboard/', {replace: true})
    return <></>
  }

  return (
    <ModalLayout>
      <SEO title='Forgot Password' />
      {isSuccessful ? (
        <SuccesModal email={email} />
      ) : (
        <Modal>
          <h1>Forgot Password</h1>
          <Form onSubmit={onSubmit}>
            <Input
              required
              label='Email'
              type='email'
              name='email'
              disabled={isAuthLoading || isLoading}
              onChange={(event) => setEmail(event.target.value)}
            />
            <LoadingSection>
              {isLoading || isAuthLoading ? (
                <LoadingSpinner color='var(--dark-blue)' />
              ) : (
                <>
                  <ErrorMessage>{error}</ErrorMessage>
                  <ActionButton tier='primary' type='submit'>
                    Send Email
                  </ActionButton>
                </>
              )}
            </LoadingSection>
          </Form>
          <p>
            I remember my password.{' '}
            <LinkButton tier='tertiary' to='/app/login' replace={true} state={location.state}>
              Log in
            </LinkButton>
            .
          </p>
        </Modal>
      )}
    </ModalLayout>
  )
}

export default ForgotPasswordPage
