import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {navigate, useLocation} from '@reach/router'
import queryString from 'query-string'
import {GlobalStyle} from 'layouts'
import {useFunctions, useUser} from 'hooks'
import {CheckCircle, SEO, LinkButton, WordLogo, LoadingSpinner} from 'components'
import BackgroundSVG from 'images/background-large.svg'

const CustomLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  padding: 5vw;

  text-align: center;
  background-color: var(--dark-blue);
  color: var(--white);
`

const Logo = styled(WordLogo)`
  height: 200px;
  width: 100%;
`

const FauxModalLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background: center / cover url(${BackgroundSVG});
  background-color: var(--light-blue);
`

const Modal = styled.div`
  border-radius: 10px;
  width: var(--mobile-width);
  max-width: 500px;
  padding: 5vw;

  text-align: center;

  background-color: var(--white);
  filter: var(--shadow);

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    margin-top: 25px;
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;

    a:hover {
      text-decoration: underline;
    }
  }
`

const LoadingSection = styled.div`
  min-height: 95px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCheckCircle = styled(CheckCircle)`
  width: 80px;
  height: 80px;
`

const SuccesModal = () => (
  <FauxModalLayout>
    <GlobalStyle />
    <SEO title='Success!' />
    <Modal>
      <StyledCheckCircle checked color='var(--green)' />
      <h1>#BEASTMODE</h1>
      <h2>You{`'`}re now a Creator</h2>
      <p>
        You did it, chief. You{`'`}ve unlocked the next level on your journey to become a prolific creator. So what are
        you waiting for? Dive right in and start building.
      </p>
      <div style={{height: 15}} />
      <LinkButton tier='tertiary' to='/app/dashboard'>
        Let{`'`}s get this bread -{`>`}
      </LinkButton>
    </Modal>
  </FauxModalLayout>
)

const CheckoutPage = () => {
  const {user} = useUser()
  const {createStripeCheckoutSession} = useFunctions()
  const {plan, currency, success} = queryString.parse(useLocation().search)
  const [status, setStatus] = useState<'loading' | 'error' | 'redirecting' | 'pending' | 'complete'>('loading')

  const leave = () => navigate('/app/dashboard', {replace: true})

  useEffect(() => {
    // Check if we're returning from the checkout page
    if (success && success == 'true') {
      setStatus('pending')

      // Validate query
    } else if (!plan || (plan !== 'monthly' && plan !== 'annual') || !currency || currency !== 'usd') {
      leave()

      // Check if we're heading to the checkout page
    } else if (user !== null) {
      if (user.proStatus !== 'active') {
        const getCheckoutSession = async () => {
          const result = await createStripeCheckoutSession(
            {plan: plan as 'monthly' | 'annual', currency: currency as 'usd'},
            {email: user.email, uid: user.uid, customerId: user.customerId ?? null}
          )

          if (result === 'error' || result === 'failure') return setStatus('error')

          // Redirect to checkout page
          setStatus('redirecting')
          result.redirect({sessionId: result.sessionId})
        }

        getCheckoutSession()
      } else setStatus('complete')
    } else leave()
  }, [])

  useEffect(() => {
    if (status === 'pending' && user !== null && user.proStatus === 'active') setStatus('complete')
  }, [user, status])

  switch (status) {
    case 'loading':
      return (
        <CustomLayout>
          <SEO title='Checkout' />
          <GlobalStyle />
          <Logo color='var(--white)' />

          <h4>
            Hold on as we clean up, just for you <span aria-label='*wink*'>😉</span>
          </h4>
          <LoadingSection>
            <LoadingSpinner color='var(--white)' />
          </LoadingSection>
        </CustomLayout>
      )
    case 'error':
      return (
        <CustomLayout>
          <SEO title='Checkout' />
          <GlobalStyle />
          <Logo color='var(--white)' />

          <h5>Something went wrong.</h5>
          <LinkButton tier='primary' to='/pricing'>
            Back
          </LinkButton>
        </CustomLayout>
      )
    case 'redirecting':
      return (
        <CustomLayout>
          <SEO title='Checkout' />
          <GlobalStyle />
          <Logo color='var(--white)' />

          <h4>
            Hold on as we clean up, just for you <span aria-label='*wink*'>😉</span>
          </h4>
          <LoadingSection>
            <LoadingSpinner color='var(--white)' />
            <p>
              <i>Redirecting...</i>
            </p>
          </LoadingSection>
        </CustomLayout>
      )
    case 'pending':
      return (
        <CustomLayout>
          <SEO title='Checkout' />
          <GlobalStyle />
          <Logo color='var(--white)' />
          <LoadingSection>
            <LoadingSpinner color='var(--white)' />
          </LoadingSection>
        </CustomLayout>
      )
    case 'complete':
      return <SuccesModal />
  }
}

export default CheckoutPage
