import React, {useState} from 'react'
import styled from 'styled-components'
import {useAuth, useUser} from 'hooks'
import {MainLayout} from 'layouts'
import {SEO, ActionButton, LinkButton, Input, Row, LoadingSpinner} from 'components'

const WhiteBkg = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--white);
`

const Content = styled.div`
  width: var(--mobile-width);
`

const H4 = styled.h4`
  color: var(--dark-grey);
  margin-bottom: 15px;
`

const Divider = styled.div`
  background-color: var(--light-grey);
  height: 2px;
  width: 100%;
  margin: 0 0 25px;
`

const Section = styled.section`
  margin-bottom: 50px;
`

const SectionHeader = ({children}) => (
  <>
    <H4>{children}</H4>
    <Divider />
  </>
)

const BasicInfoForm = styled.form`
  width: 100%;

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

export const SettingsPage = () => {
  const {sendPasswordResetEmail} = useAuth()
  const {user, updateUser} = useUser()
  const [isSubmitFormLoading, setSubmitFormLoading] = useState(false)
  const [isSendEmailLoading, setSendEmailLoading] = useState(false)
  const [newFirstName, setFirstName] = useState('')
  const [newLastName, setLastName] = useState('')

  if (user === null) return <h2>Loading...</h2>

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitFormLoading(true)

    const success = await updateUser({newFirstName, newLastName})

    if (success) {
      setFirstName('')
      setLastName('')
    } else {
      // TODO: cleaner error message
      alert('Something went wrong. Please try again later.')
    }

    setSubmitFormLoading(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    switch (name) {
      case 'firstName':
        return setFirstName(value)
      case 'lastName':
        return setLastName(value)
    }
  }

  const handleSendEmail = async () => {
    setSendEmailLoading(true)

    const result = await sendPasswordResetEmail(user.email)

    // TODO: use cleaner modals
    switch (result) {
      case 'success':
        alert('Email sent successfully!')
        break
      case 'invalid-email':
        alert(`Your email "${user.email}" is invalid.`)
        break
      case 'no-user':
        alert('There is no account attached to this email.')
        break
      case 'error':
        alert('Something went wrong. Please try again later.')
        break
    }

    setSendEmailLoading(false)
  }

  return (
    <WhiteBkg>
      <MainLayout>
        <SEO title='Settings' />
        <Content>
          <h1>Settings</h1>
          <Section>
            <SectionHeader>Basic Information</SectionHeader>
            <BasicInfoForm onSubmit={handleSubmit}>
              <Row gapSize='25px'>
                <Input
                  label='First Name'
                  type='text'
                  name='firstName'
                  placeholder={user.firstName}
                  onChange={handleChange}
                  value={newFirstName}
                />
                <Input
                  label='Last Name'
                  type='text'
                  name='lastName'
                  placeholder={user.lastName}
                  onChange={handleChange}
                  value={newLastName}
                />
              </Row>
              {isSubmitFormLoading ? (
                <LoadingSpinner />
              ) : (
                <ActionButton tier='primary' type='submit' disabled={newFirstName === '' && newLastName === ''}>
                  Save Changes
                </ActionButton>
              )}
            </BasicInfoForm>
          </Section>
          <Section>
            <SectionHeader>Reset Password</SectionHeader>
            <p>Press the button below to receive an email and reset your password.</p>
            {isSendEmailLoading ? (
              <LoadingSpinner />
            ) : (
              <ActionButton tier='primary' onClick={handleSendEmail}>
                Send Email
              </ActionButton>
            )}
          </Section>
          {user.customerId !== null && (
            <Section>
              <SectionHeader>Manage Billing</SectionHeader>
              <p>Our billing is handled by Stripe. Press the button below to access your billing portal.</p>
              <LinkButton tier='primary' to='/app/billing'>
                Open Billing Portal
              </LinkButton>
            </Section>
          )}
        </Content>
      </MainLayout>
    </WhiteBkg>
  )
}

export default SettingsPage
