import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Img, {FluidObject} from 'gatsby-image'
import {useUser} from 'hooks'
import {MainLayout} from 'layouts'
import {AnchorButton, LinkButton, ProgressBar, SEO} from 'components'
import useStrapiContent from './useStrapiContent'

const Greeting = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 25px;
  padding-bottom: calc(25px + 5em);

  background-color: var(--white);

  * {
    margin: 0;
  }

  h1 {
    margin-bottom: 10px;
  }
`

const EyebrowText = styled.p`
  margin: 0;

  font-family: var(--title-font);
  font-weight: bold;
  color: var(--blue);
`

const ProTag = styled.div`
  border-radius: 5px;
  padding: 5px 10px;

  text-align: center;
  font-family: var(--title-font);
  font-weight: bold;

  background-color: var(--green);
  color: var(--white);
`

const FloatingDock = styled.div`
  position: absolute;
  bottom: -5em;

  display: flex;
  align-items: center;

  height: 10em;
  max-width: var(--mobile-width);
  border-radius: 10px;
  padding: 30px 25px;

  background-color: var(--white);
  filter: var(--shadow);
`

const Stat = styled.div`
  text-align: center;
  width: 8em;
`

const StrongNumber = styled.div`
  font-family: var(--secondary-font);
  font-size: 2.5em;
  font-weight: bold;
`

const Divider = styled.div`
  height: 6em;
  border: 1px solid var(--light-grey);
  margin: 0 25px;
`

const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: var(--mobile-width);
  padding-top: 5em;
  margin-bottom: 50px;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: var(--content-width);
  }
`

const FilterMenu = styled.div`
  width: 100%;
  max-width: 300px;

  margin-top: 50px;
  padding-bottom: 25px;
  border-top: 2px solid var(--medium-grey);
  border-bottom: 2px solid var(--medium-grey);

  text-align: center;

  h4 {
    color: var(--dark-grey);
    margin: 25px 0;
  }

  @media only screen and (min-width: 900px) {
    flex-shrink: 0;

    width: unset;
    max-width: 250px;
    padding-right: 25px;
    margin-right: 25px;
    border: none;
    border-right: 2px solid var(--medium-grey);

    text-align: left;
  }
`

const FilterButton = styled.button<{selected: boolean}>`
  display: inline-block;

  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;

  color: var(--black);
  background: none;
  background-color: ${({selected}) => (selected ? `var(--violet)` : `none`)};

  font-family: var(--secondary-font);
  font-size: 1em;

  @media only screen and (min-width: 900px) {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    transition: background-color var(--transition);

    &:hover {
      cursor: pointer;
      background-color: var(--white);
    }
  }
`

const MessageBox = styled.div`
  display: none;

  @media only screen and (min-width: 900px) {
    display: block;

    border-radius: 5px;
    margin-top: 75px;
    padding: 20px;

    background-color: var(--light-grey);

    h6 {
      margin: 0;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  h3 {
    margin-top: 50px;
  }

  @media only screen and (min-width: 900px) {
    align-items: flex-start;
  }
`

const TileView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  @media only screen and (min-width: 900px) {
    justify-content: flex-start;
  }
`

const ContentPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  border: 2px solid var(--light-grey);
  border-radius: 5px;
  padding: 50px 5vw;
  margin: 25px 0;

  text-align: center;
  background-color: var(--white);

  p {
    margin: 0 0 5vw;
    font-size: 1.25em;
    font-weight: 600;
  }

  @media only screen and (min-width: 900px) {
    margin: 50px 0;
    padding: 50px 25px;

    p {
      margin: 0 0 25px;
    }
  }
`

const ContentTile = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 280px;
  padding: 25px;
  margin: 0 10px 20px;
  border-radius: 10px;

  background-color: var(--white);

  transition: var(--transition);

  strong {
    font-size: 1.1em;
    font-family: var(--secondary-font);
    font-weight: 600;
    transition: var(--transition);
  }

  &:hover {
    filter: var(--shadow);
  }

  &:hover strong {
    color: var(--blue);
  }

  @media only screen and (min-width: 900px) {
    margin: 0 20px 20px 0;
  }
`

const ContentIcon = styled(Img)<{fluid: FluidObject}>`
  width: 70%;
  max-height: 115px;
  margin-bottom: 10%;
`

interface DashboardContent {
  slug: string
  title: string
  completion: number
  icon: FluidObject
}

interface DashboardData {
  activeProjects: Array<DashboardContent>
  completedProjects: Array<DashboardContent>
  activeTopics: Array<DashboardContent>
  completedTopics: Array<DashboardContent>
}

const DashboardHomePage = () => {
  const {user, projects: userProjects, topics: userTopics} = useUser()
  const {strapiProjects, strapiTopics} = useStrapiContent()

  const [dashboardData, setDashboard] = useState<DashboardData>({
    activeProjects: [],
    completedProjects: [],
    activeTopics: [],
    completedTopics: [],
  })
  const [filter, setFilter] = useState<'projects' | 'topics'>('projects')

  const today = new Date()
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  useEffect(() => {
    if (userProjects !== null) {
      const projects = userProjects.map((project) => ({
        slug: project.slug,
        title: strapiProjects[project.slug].title,
        completion: project.watchedLectures.length / (strapiProjects[project.slug].lessons.length + 1),
        icon: strapiProjects[project.slug].gatsbyIcon.childImageSharp.fluid,
      }))

      setDashboard((prevState) => ({
        ...prevState,
        activeProjects: projects.filter((project) => project.completion !== 1),
        completedProjects: projects.filter((project) => project.completion == 1),
      }))
    }
  }, [userProjects])

  useEffect(() => {
    if (userTopics !== null) {
      const topics = userTopics.map((topic) => ({
        slug: topic.slug,
        title: strapiTopics[topic.slug].title,
        completion: topic.watchedLectures.length / strapiTopics[topic.slug].lessons.length,
        icon: strapiTopics[topic.slug].gatsbyIcon.childImageSharp.fluid,
      }))

      setDashboard((prevState) => ({
        ...prevState,
        activeTopics: topics.filter((topic) => topic.completion !== 1),
        completedTopics: topics.filter((topic) => topic.completion === 1),
      }))
    }
  }, [userTopics])

  // This is just for typing, all of these should never actually be null
  if (user === null || userProjects === null || userTopics === null) return <div>Loading...</div>

  return (
    <MainLayout>
      <SEO title='Dashboard' />
      <Greeting>
        <EyebrowText>{today.toLocaleDateString('en-US', options).toUpperCase()}</EyebrowText>
        <h1>Welcome, {user.firstName}!</h1>
        {user.proStatus === 'active' && <ProTag>CREATOR</ProTag>}
        <FloatingDock>
          <Stat>
            <StrongNumber>{dashboardData.completedTopics.length}</StrongNumber> Topic
            {dashboardData.completedTopics.length === 1 ? '' : 's'} Completed
          </Stat>
          <Divider />
          <Stat>
            <StrongNumber>{dashboardData.completedProjects.length}</StrongNumber>
            Project
            {dashboardData.completedProjects.length === 1 ? '' : 's'} Completed
          </Stat>
        </FloatingDock>
      </Greeting>
      <Dashboard>
        <FilterMenu>
          <h4>VIEW</h4>
          <FilterButton selected={filter === 'projects'} onClick={() => setFilter('projects')}>
            Projects
          </FilterButton>
          <FilterButton selected={filter === 'topics'} onClick={() => setFilter('topics')}>
            Topics
          </FilterButton>
          <MessageBox>
            <h6>What can we do better?</h6>
            <p>Give us feedback on our new platform, projects you want to see or anything on your mind!</p>
            <AnchorButton
              tier='tertiary'
              href='https://forms.gle/s16cZvqg3d4ht2yR6'
              target='_blank'
              rel='noopener noreferrer external'
            >
              Feedback form
            </AnchorButton>
          </MessageBox>
        </FilterMenu>
        <Content>
          {filter === 'projects' &&
            (userProjects.length === 0 ? (
              <ContentPlaceholder>
                <p>You have yet to start any projects.</p>
                <LinkButton tier='primary' to='/projects'>
                  Browse Projects
                </LinkButton>
              </ContentPlaceholder>
            ) : (
              <>
                {dashboardData.activeProjects.length !== 0 && (
                  <>
                    <h3>Active Projects</h3>
                    <TileView>
                      {dashboardData.activeProjects.map((project: DashboardContent, i) => (
                        <ContentTile key={i} to={`/app/learn?contentType=project&slug=${project.slug}`}>
                          <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={project.icon} />
                          <strong>{project.title}</strong>
                          <ProgressBar percentage={project.completion} />
                        </ContentTile>
                      ))}
                    </TileView>
                  </>
                )}
                {dashboardData.completedProjects.length !== 0 && (
                  <>
                    <h3>Completed Projects</h3>
                    <TileView>
                      {dashboardData.completedProjects.map((project: DashboardContent, i) => (
                        <ContentTile key={i} to={`/projects/${project.slug}`}>
                          <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={project.icon} />
                          <strong>{project.title}</strong>
                          <ProgressBar percentage={project.completion} />
                        </ContentTile>
                      ))}
                    </TileView>
                  </>
                )}
              </>
            ))}
          {filter === 'topics' &&
            (userTopics.length === 0 ? (
              <ContentPlaceholder>
                <p>You have yet to start any topics.</p>
                <LinkButton tier='primary' to='/topics'>
                  Browse Topics
                </LinkButton>
              </ContentPlaceholder>
            ) : (
              <>
                {dashboardData.activeTopics.length !== 0 && (
                  <>
                    <h3>Active Topics</h3>
                    <TileView>
                      {dashboardData.activeTopics.map((topic: DashboardContent, i) => (
                        <ContentTile key={i} to={`/app/learn?contentType=topic&slug=${topic.slug}`}>
                          <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={topic.icon} />
                          <strong>{topic.title}</strong>
                          <ProgressBar percentage={topic.completion} />
                        </ContentTile>
                      ))}
                    </TileView>
                  </>
                )}

                {dashboardData.completedTopics.length !== 0 && (
                  <>
                    <h3>Completed Topics</h3>
                    <TileView>
                      {dashboardData.completedTopics.map((topic: DashboardContent, i) => (
                        <ContentTile key={i} to={`/topics/${topic.slug}`}>
                          <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={topic.icon} />
                          <strong>{topic.title}</strong>
                          <ProgressBar percentage={topic.completion} />
                        </ContentTile>
                      ))}
                    </TileView>
                  </>
                )}
              </>
            ))}
        </Content>
      </Dashboard>
    </MainLayout>
  )
}

export default DashboardHomePage
