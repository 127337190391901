import React, {useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate, WindowLocation} from '@reach/router'

import {ActionButton, AnchorButton, Input, LinkButton, LoadingSpinner, Password, Row, SEO} from 'components'
import {ModalLayout} from 'layouts'
import {useAuth} from 'hooks'

const Modal = styled.div`
  border-radius: 10px;
  width: var(--mobile-width);
  padding: 5vw;

  text-align: center;

  background-color: var(--white);
  filter: var(--shadow);

  h1 {
    margin-top: 0;
  }

  a {
    color: var(--blue);
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;
    max-width: 850px;

    a:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 75px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`

const LoadingSection = styled.div`
  min-height: 95px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const ErrorMessage = styled.p`
  min-height: 1.2em;
  margin: 0 0 25px;
  font-size: 0.9em;
  color: var(--red);
`

interface FormFields {
  firstName: string
  lastName: string
  email: string
  password: string
}

const DEFAULT_FORM: FormFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
}

export const SignUpPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const {isLoading, user, signUp} = useAuth()

  const [formData, setFormData] = useState<FormFields>(DEFAULT_FORM)
  const [error, setError] = useState('')

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    switch (name) {
      case 'firstName':
        return setFormData((prevState) => {
          return {
            ...prevState,
            firstName: value,
          }
        })
      case 'lastName':
        return setFormData((prevState) => {
          return {
            ...prevState,
            lastName: value,
          }
        })
      case 'email':
        return setFormData((prevState) => {
          return {
            ...prevState,
            email: value,
          }
        })
      case 'password':
        return setFormData((prevState) => {
          return {
            ...prevState,
            password: value,
          }
        })
    }
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')

    const {firstName, lastName, email, password} = formData
    const result = await signUp(firstName, lastName, email, password)

    switch (result) {
      case 'success':
        return
      case 'used-email':
        setError('This email is taken.')
        break
      case 'invalid-email':
        setError('This email address is invalid.')
        break
      case 'weak-password':
        setError('This password is too weak.')
        break
      case 'error':
        setError('Something went wrong. Please try again later.')
        break
    }
  }

  if (user) {
    const {state} = location as WindowLocation<{successUrl: string}>

    if (state !== null && state.successUrl) {
      console.log('Heading to ', state.successUrl)
      navigate(state.successUrl, {replace: true})
    } else {
      console.log('Heading to /app/dashboard')
      navigate('/app/dashboard', {replace: true})
    }
  }

  return (
    <ModalLayout>
      <SEO title='Get Started' />
      <Modal>
        <h1>Sign up and start building!</h1>
        <Form onSubmit={onSubmit}>
          <Row gapSize='25px'>
            <Input required label='First Name' type='text' name='firstName' disabled={isLoading} onChange={onChange} />
            <Input required label='Last Name' type='text' name='lastName' disabled={isLoading} onChange={onChange} />
          </Row>
          <div />
          <Row gapSize='25px'>
            <Input required label='Email' type='email' name='email' disabled={isLoading} onChange={onChange} />
            <Password required label='Password' name='password' disabled={isLoading} onChange={onChange} />
          </Row>
          <LoadingSection>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <ErrorMessage>{error}</ErrorMessage>
                <ActionButton tier='primary' type='submit'>
                  Get Started
                </ActionButton>
              </>
            )}
          </LoadingSection>
        </Form>
        <p>
          Already have an account?{' '}
          <LinkButton tier='tertiary' to='/app/login' replace={true} state={location.state}>
            Log in
          </LinkButton>
          .
        </p>
        <p>
          By creating an account you accept our{' '}
          <AnchorButton
            tier='tertiary'
            href='https://www.notion.so/Terms-of-Service-fe1fcd234a38487098a8bef1cfc86da7'
            target='_blank'
            rel='noopener noreferrer external'
          >
            Terms of Service
          </AnchorButton>{' '}
          and{' '}
          <AnchorButton
            tier='tertiary'
            href='https://app.termly.io/document/privacy-policy/5cc056ef-4bb7-46a4-b51d-8b34c6bba8b6'
            target='_blank'
            rel='noopener noreferrer external'
          >
            Privacy Policy
          </AnchorButton>
          .
        </p>
      </Modal>
    </ModalLayout>
  )
}

export default SignUpPage
