import {useStaticQuery, graphql} from 'gatsby'
import {FluidObject} from 'gatsby-image'

export interface StrapiContentType {
  slug: string
  title: string
  free: boolean
  lessons: Array<{
    video: {
      source: string
    }
  }>
  gatsbyIcon: {childImageSharp: {fluid: FluidObject}}
}

export interface StrapiProjectType extends StrapiContentType {
  description: string
  categories: Array<{
    tag: string
  }>
  introduction: {
    duration: number
  }
  lessons: Array<{
    video: {
      source: string
      duration: number
    }
  }>
}

const useStrapiContent = () => {
  const {
    allStrapiProject,
    allStrapiTopic,
  }: {
    allStrapiProject: {
      nodes: Array<StrapiProjectType>
    }
    allStrapiTopic: {
      nodes: Array<StrapiContentType>
    }
  } = useStaticQuery(graphql`
    query DashboardQuery {
      allStrapiProject {
        nodes {
          slug
          title
          description
          free
          categories {
            tag
          }
          introduction {
            duration
          }
          lessons {
            video {
              duration
              source
            }
          }
          gatsbyIcon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allStrapiTopic {
        nodes {
          slug
          title
          free
          lessons {
            video {
              source
            }
          }
          gatsbyIcon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const strapiProjects: {
    [slug: string]: StrapiProjectType
  } = allStrapiProject.nodes.reduce(
    (prevObject, strapiProject) => ({
      ...prevObject,
      [strapiProject.slug]: strapiProject,
    }),
    {}
  )

  const strapiTopics: {
    [slug: string]: StrapiContentType
  } = allStrapiTopic.nodes.reduce(
    (prevObject, strapiTopic) => ({
      ...prevObject,
      [strapiTopic.slug]: strapiTopic,
    }),
    {}
  )

  return {
    strapiProjects,
    strapiTopics,
  }
}

export default useStrapiContent
